.smsOptInModal {
  margin-top: 40px;
  padding: 24px;

  h4 {
    text-align: center;
  }

  .actionButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;

    button {
      flex: 1;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.buttonPrimary {
  composes: buttonPrimary from '../../../../styles/buttons.scss';
}

.buttonSecondary {
  composes: buttonSecondary from '../../../../styles/buttons.scss';
}