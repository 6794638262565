@import '../../../styles/variables.scss';

.basketSummary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .basketSummary__container {
    .productSummary {
      display: flex;
      flex-direction: column;
      color: $gray-dark;
      font-weight: $weight-medium;
      align-items: flex-start;
      margin-top: $spacing-base * 4;
      @media screen and (min-width: $screen-desktop-min) and (min-height: $screen-desktop-height-min) {
        margin-top: $spacing-base * 2;
      }

      .productSummary__name {
        color: $gray-medium;
        display: flex;
        align-items: center;
        svg {
          margin-left: 6px;
        }
      }
      .productSummary__totalAmount {
        font-size: $font-xxlarge;
        font-variant-numeric: tabular-nums;
        font-weight: $weight-semibold;
      }
    }
    .orderDetails {
      display: none;

      @media screen and (min-width: $screen-desktop-min) and (min-height: $screen-desktop-height-min) {
        display: block;
      }
    }
  }
}

.securely {
  margin-left: 6px;
  display: flex;
  align-items: center;
}
