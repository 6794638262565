@import './variables.scss';

.button {
  position: relative;
  min-width: 160px;
  height: 50px;
  padding-left: 30px;
  padding-right: 30px;
  font-family: $font-family;
  font-size: $font-base;
  line-height: $spacing-base * 3;
  font-weight: $weight-semibold;
  color: $gray;
  text-align: center;
  text-decoration: none;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;

  flex: 0 0 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  transition: all 0.2s $custom-easing;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  img {
    margin-right: calc($spacing-small / 2);
  }
}

.buttonSmall {
  height: 50px;
}

.buttonXSmall {
  height: 40px;
}

.buttonSuccess {
  @extend .button;

  background-color: $success2;
  color: $white;

  &:hover {
    box-shadow: $custom-box-shadow;
  }
}

.buttonPrimary {
  @extend .button;

  background-color: $brand-primary;
  color: $gray;

  &:hover {
    box-shadow: $custom-box-shadow;
  }
}

.buttonInverted {
  @extend .button;

  background-color: transparent;
  color: $gray;
  border: 1px solid $gray;

  &:hover {
    box-shadow: $custom-box-shadow;
  }
}

.buttonGhost {
  @extend .button;

  background-color: white;
  color: $gray;
  border: 1px solid $gray;

  &:hover {
    box-shadow: $custom-box-shadow;
  }
}

.buttonGhostPrimary {
  @extend .button;

  background-color: white;
  color: $gray;
  border: 1px solid $brand-primary;

  &:hover {
    box-shadow: $custom-box-shadow;
  }
}

.buttonGhostInverted {
  @extend .button;

  background-color: transparent;
  color: white;
  border: 1px solid white;

  &:hover {
    box-shadow: $custom-box-shadow;
  }
}

.buttonLink {
  @extend .button;

  color: $gray-medium;
  min-width: auto;
  height: auto;
  padding: 0;
  background: none;
  font-weight: $weight-normal;

  &:hover {
    color: $gray-dark;
  }
}

.link {
  @extend .buttonLink;

  font-size: $font-xsmall;
  font-weight: $weight-semibold;
  line-height: $spacing-base * 3;

  &:hover {
    color: $brand-primary;
  }
}

.linkBack {
  @extend .buttonLink;

  font-size: $font-xsmall;
  font-weight: $weight-semibold;
  line-height: $spacing-base * 3;
  padding-left: $spacing-base * 3;

  &:hover {
    color: $brand-primary;
  }

  &:before {
    left: 0;
    right: auto;
    transform: translateY(calc(-50% - 1px)) rotate(135deg);
  }
}

.buttonFixedWidth {
  width: 100%;
  max-width: 274px; // equal to 3 cols
}

.buttonClose {
  @extend .button;

  position: absolute;
  top: 0;
  right: 0;
  min-width: auto;
  padding: $spacing-small;
  background-color: transparent;

  @media screen and (min-width: $screen-desktop-min) {
    top: $spacing-small;
    right: $spacing-small;
  }

  img {
    width: 14px;
    height: 14px;
  }
}

.loader {
  display: inline-block;
  margin: 0 10px;
  font-size: 4px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  text-indent: -9999em;
  animation: load 1.1s infinite ease;
  transform: translateZ(0);
}

.loaderPrimary {
  @extend .loader;

  animation: loadPrimary 1.1s infinite ease;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $z-page-loader;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(254, 246, 220, 0.8);
}

@keyframes load {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

@keyframes loadPrimary {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 211, 38, 1),
      1.8em -1.8em 0 0em rgba(255, 211, 38, 0.2),
      2.5em 0em 0 0em rgba(255, 211, 38, 0.2),
      1.75em 1.75em 0 0em rgba(255, 211, 38, 0.2),
      0em 2.5em 0 0em rgba(255, 211, 38, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 211, 38, 0.2),
      -2.6em 0em 0 0em rgba(255, 211, 38, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 211, 38, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 211, 38, 0.7),
      1.8em -1.8em 0 0em rgba(255, 211, 38, 1),
      2.5em 0em 0 0em rgba(255, 211, 38, 0.2),
      1.75em 1.75em 0 0em rgba(255, 211, 38, 0.2),
      0em 2.5em 0 0em rgba(255, 211, 38, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 211, 38, 0.2),
      -2.6em 0em 0 0em rgba(255, 211, 38, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 211, 38, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 211, 38, 0.5),
      1.8em -1.8em 0 0em rgba(255, 211, 38, 0.7),
      2.5em 0em 0 0em rgba(255, 211, 38, 1),
      1.75em 1.75em 0 0em rgba(255, 211, 38, 0.2),
      0em 2.5em 0 0em rgba(255, 211, 38, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 211, 38, 0.2),
      -2.6em 0em 0 0em rgba(255, 211, 38, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 211, 38, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 211, 38, 0.2),
      1.8em -1.8em 0 0em rgba(255, 211, 38, 0.5),
      2.5em 0em 0 0em rgba(255, 211, 38, 0.7),
      1.75em 1.75em 0 0em rgba(255, 211, 38, 1),
      0em 2.5em 0 0em rgba(255, 211, 38, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 211, 38, 0.2),
      -2.6em 0em 0 0em rgba(255, 211, 38, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 211, 38, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 211, 38, 0.2),
      1.8em -1.8em 0 0em rgba(255, 211, 38, 0.2),
      2.5em 0em 0 0em rgba(255, 211, 38, 0.5),
      1.75em 1.75em 0 0em rgba(255, 211, 38, 0.7),
      0em 2.5em 0 0em rgba(255, 211, 38, 1),
      -1.8em 1.8em 0 0em rgba(255, 211, 38, 0.2),
      -2.6em 0em 0 0em rgba(255, 211, 38, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 211, 38, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 211, 38, 0.2),
      1.8em -1.8em 0 0em rgba(255, 211, 38, 0.2),
      2.5em 0em 0 0em rrgba(255, 211, 38, 0.2),
      1.75em 1.75em 0 0em rgba(255, 211, 38, 0.5),
      0em 2.5em 0 0em rgba(255, 211, 38, 0.7),
      -1.8em 1.8em 0 0em rgba(255, 211, 38, 1),
      -2.6em 0em 0 0em rgba(255, 211, 38, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 211, 38, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 211, 38, 0.2),
      1.8em -1.8em 0 0em rgba(255, 211, 38, 0.2),
      2.5em 0em 0 0em rgba(255, 211, 38, 0.2),
      1.75em 1.75em 0 0em rgba(255, 211, 38, 0.2),
      0em 2.5em 0 0em rgba(255, 211, 38, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 211, 38, 0.7),
      -2.6em 0em 0 0em rgba(255, 211, 38, 1),
      -1.8em -1.8em 0 0em rgba(255, 211, 38, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 211, 38, 0.2),
      1.8em -1.8em 0 0em rgba(255, 211, 38, 0.2),
      2.5em 0em 0 0em rgba(255, 211, 38, 0.2),
      1.75em 1.75em 0 0em rgba(255, 211, 38, 0.2),
      0em 2.5em 0 0em rgba(255, 211, 38, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 211, 38, 0.5),
      -2.6em 0em 0 0em rgba(255, 211, 38, 0.7),
      -1.8em -1.8em 0 0em rgba(255, 211, 38, 1);
  }
}
