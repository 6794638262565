@import '../../../../styles/variables.scss';
@import '../../../../styles/buttons.scss';

.button {
  @extend .buttonPrimary;
  margin-bottom: $spacing-base;
}

.buttonFullWidth {
  @extend .button;
  width: 100%;
}

.success,
.error {
  font-weight: $weight-semibold;
  font-size: $font-base;
  text-align: left;
  margin: 0;
  margin-top: $spacing-base * 2;
  margin-bottom: $spacing-base * 3;
}

.error {
  color: $error;
}

.success {
  color: $success;
}

.loader {
  @extend .loader;
  position: absolute;
  right: 20px;
}
