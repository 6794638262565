@import '../../../../styles/variables.scss';

.placeholder {
  composes: placeholder from '../../../../styles/forms.scss';
}

.placeholderActive {
  composes: placeholderActive from '../../../../styles/forms.scss';
}

.error {
  composes: error from '../../../../styles/forms.scss';
}

.container {
  position: relative;
  display: block;
  width: 100%;
  height: $spacing-base * 7;
  margin-bottom: $spacing-base;
  background: $brand-primary-lighter;
  color: $gray;

  input {
    width: 100%;
    height: 100%;
    padding: $spacing-base * 2 $spacing-base * 2 0 $spacing-base * 2;
    border: 1px solid $gray-lighter;
    border-radius: 0;
    outline: 0;
    transition: 0.2s all $custom-easing;

    &:focus,
    &:active {
      border-color: $gray;
    }
  }
}

.phoneInputContainer {
  composes: container;
  position: relative;
  .phoneInput {
    height: 100%;
    border: 1px solid $gray-lighter;
    background-color: $white;
    :global(.PhoneInputCountry) {
      margin-top: 18px;
      margin-left: 16px;
      margin-right: 1px;
    }
    input {
      border: none;
      padding-left: 12px;
    }
  }
}

.phoneInputErrorContainer {
  composes: phoneInputContainer;
  .phoneInput {
    border: 1px solid $error;
  }
}

.phoneInputPlaceholder {
  composes: placeholderActive;
  padding-left: 66px;
}

.phoneInputError {
  composes: error;
}
.tooltip {
  //display: flex;
  display: none;
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  border: 1px solid $grey-light-3;
  color: $grey-light-3;
  font-size: 10px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-bottom: 2px;
  user-select: none;
}

.tooltipText{
  position: absolute;
  background-color: $grey-light-3;
  width: 198px;
  font-size: $font-xxsmall;
  color:  $white;
  font-weight: $weight-medium;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  right: -23px;
  top: -58px;
  box-sizing: border-box;
  user-select: none;
  &:after{
    content: "";
    position: absolute;
    top: 100%;
    right: 24px;
    width: 0;
    border-top: 6px solid $grey-light-3;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    cursor: pointer;
  }
}
