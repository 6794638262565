@import '../../../styles/variables.scss';
@import '../../../styles/buttons.scss';
@import '../../../styles/forms.scss';

.buttonInverted {
  @extend .buttonInverted;
  width: 100%;
}

.overflow {
  display: flex;
  flex-direction: column;
  border: 1px solid $gray-lighter2;
  border-radius: $border-radius;
  max-height: 300px;
  padding: $spacing-base * 2;
  position: relative;
  margin-bottom: 149px;

  h5 {
    margin-bottom: $spacing-base * 2;
    font-size: $font-xlsmall;
  }

  form {
    overflow: auto;
    border-top: 1px solid $gray-lighter2;

    .buttons {
      position: absolute;
      bottom: -125px;
      width: 100%;
      left: 0;
    }
    .error {
      position: absolute;
      bottom: -85px;
    }
  }
}

.success {
  font-weight: $weight-semibold;
  font-size: $font-base;
  text-align: left;
  margin: 0;
  margin-top: $spacing-base * 2;
  margin-bottom: $spacing-base * 3;
  color: $success;
}

.textBetweenLabels {
  margin-top: $spacing-small;
  margin-bottom: $spacing-small;
  text-align: center;
}
.error {
  @extend .error;
}

.buttons {
  position: relative;
}

.singleRow {
  display: flex;
  justify-content: space-between;

  > div {
    width: 49%;
  }
}

.inputNote{
  display: flex;
  color: $gray-lighter;
  font-size: $font-xxsmall;
  font-weight: $weight-medium;
  margin-bottom: $spacing-base;
  margin-top: 0;
}
