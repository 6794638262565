// fonts
@import url('https://use.typekit.net/qjl2mpy.css');

@font-face {
  font-family: 'Hurme';
  src: url('../assets/fonts/hurme-geometric-bold.woff2') format('woff2'),
    url('../assets/fonts/hurme-geometric-bold.woff') format('woff'),
    url('../assets/fonts/hurme-geometric-bold.otf') format('opentype'),
    url('../assets/fonts/hurme-geometric_bold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

$header-height: 60px;
$basket-width: 380px;

$font-family: 'sofia-pro', sans-serif;
$font-family-header: 'Hurme', Helvetica, Arial, Sans-Serif;

$weight-light: 200;
$weight-normal: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;

$font-xxsmall: 12px;
$font-xsmall: 14px;
$font-xlsmall: 16px;
$font-small: 20px;
$font-medium: 24px;
$font-medium2: 22px;
$font-large: 26px;
$font-xlarge: 28px;
$font-xmlarge: 32px;
$font-xmlarge2: 32px;
$font-xxlarge: 36px;
$font-xxxlarge2: 40px;
$font-xxxlarge: 46px;

$font-base: $font-xlsmall;

// spacing
$spacing-base: 8px;

$spacing-small: $spacing-base * 2;
$spacing-large: $spacing-base * 3;
$spacing-xlarge: $spacing-base * 4;
$spacing-content: $spacing-base * 2;

//colors
$brand-primary: #ffd326;
$brand-primary-darker: #f8b91a;
$brand-primary-light: #fbf5e5;
$brand-primary-lighter: #fef6dc;
$brand-primary-lightest: #fffbed;
$brand-primary-dark: #00857e; // change with vitl
$brand-secondary: #fff9e0; // change with vitl
$white: #ffffff;
$gray: #333333;
$gray-medium: #1a1a1a80;
$gray-light: #79776b;
$grey-light-2: #777777;
$grey-light-3: #6f6f6f;
$gray-lighter: #919191;
$text-grey: #aeaa9a;
$gray-lighter2: #d1cdc1;
$gray-lightest: #f9f8f4;
$grey-lightest-2: #cccccc;
$gray-dark: #2b2b2b;
$gray-border-content: #ebebf1;
$success: #46bc8f;
$success2: #22a5a5;
$error: #980f0f;

// media queries
$screen-mobile: 375px;
$screen-tablet: 768px;
$screen-desktop: 992px;
$screen-desktop-xl: 1280px;
$screen-tablet-height: 610px;

//Height breakpoints
$screen-desktop-height-min: $screen-tablet-height;

//Width breakpoints
$screen-mobile-min: $screen-mobile;
$screen-mobile-max: $screen-tablet - 1;
$screen-tablet-min: $screen-tablet;
$screen-tablet-max: $screen-desktop - 1;
$screen-desktop-min: $screen-desktop;
$screen-desktop-max: $screen-desktop-xl - 1;
$screen-desktop-xl-min: $screen-desktop-xl;

// container
$container-mobile: 480px;
$container-tablet: 640px;
$container-desktop: 953px;
$container-desktop-xl: 1200px;

// grid
$grid-breakpoints: (
  xs: $screen-mobile,
  md: $screen-tablet,
  lg: $screen-desktop,
  xl: $screen-desktop-xl,
);

$container-max-widths: (
  xs: $container-mobile,
  md: $container-tablet,
  lg: $container-desktop,
  xl: $container-desktop-xl,
);

$grid-columns: 12;
$grid-gutter-width: 16px;

// other
$border: 1px solid rgba(26, 26, 26, 0.1);
$transition-slow: all 0.25s ease 50ms;
$transition-fast: all 0.1s ease 0ms;
$border-radius: 5px;
$custom-easing: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$custom-box-shadow: 0 24px 32px rgb(0 0 0 / 10%);
$custom-box-shadow2: 0 0.4px 0.4px rgba(0, 0, 0, 0.02),
  0 1px 1px rgba(0, 0, 0, 0.03), 0 2.1px 2.1px rgba(0, 0, 0, 0.04),
  0 4.4px 4.4px rgba(0, 0, 0, 0.05), 0 12px 12px rgba(0, 0, 0, 0.07);

// z-index
$z-banner: 25;
$z-header: 50;
$z-basket: 75;
$z-modal: 125;
$z-page-loader: 9999;

%arrow {
  content: '';
  position: absolute;
  top: 50%;
  right: 5px;
  padding: 3px;
  border-width: 0 2px 2px 0;
  border-style: solid;
  border-color: $gray;
  transform: translateY(calc(-50% - 1px)) rotate(-45deg);
  transform-origin: 66.66% 66.66%;
  transition: transform 0.2s $custom-easing;
}

%arrowDown {
  &:after {
    @extend %arrow;
    border-color: $gray-medium;
    transform: translateY(calc(-50% - 1px)) rotate(45deg);
  }
}

%arrowUp {
  &:after {
    @extend %arrow;
    border-color: $gray-medium;
    transform: translateY(calc(-50% - 1px)) rotate(-135deg);
  }
}
