@import '../../../styles/variables.scss';

.magicLinkSent {
  display: flex;
  flex-direction: column;
  align-items: center;
  .success {
    font-weight: $weight-semibold;
    font-size: $font-base;
    text-align: center;
    margin: 0;
    margin-top: $spacing-base * 2;
    color: $success;
  }
  .emailIcon {
    height: 40px;
  }
  .button {
    cursor: pointer;
    color: $gray-dark;
    margin-top: $spacing-base;
    margin-bottom: 0;
    display: flex;
    align-items: baseline;
    img {
      margin-right: $spacing-base;
    }
  }
  .options {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
}
