@import '../../styles/variables.scss';
@import '../../styles/buttons.scss';

.buttonLink {
  @extend .buttonLink;
  margin-bottom: $spacing-base * 4;
}

.walletButton {
  padding: 0 $spacing-base * 4;
  width: calc(100% + $spacing-base * 8);
  margin-left: -4 * $spacing-base;
}
.textWallet {
  margin: $spacing-base * 4 0;
  text-align: center;

  > div {
    text-align: center;
  }
}

.overviewWithWallet {
  padding: $spacing-base * 4;
  border: $border;
  border-radius: $border-radius;
  width: calc(100% + $spacing-base * 8);
  margin-left: -4 * $spacing-base;
}

.trustpilot {
  margin-top: 24px;
  .trustpilotWidget {
    max-width: 270px;
  }
  p {
    font-size: $font-xsmall;
    color: $gray;
    display: flex;
    align-items: center;
    margin: 6px 0px;
    svg {
      margin-right: $spacing-base;
    }
  }
}
