@import '../../../styles/variables.scss';

.button-close {
  composes: buttonClose from '../../../styles/buttons.scss';
  top: 7px;
}

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparentize($gray, 0.2);
  z-index: $z-modal;

  display: flex;
  align-items: center;
  overflow: auto;
}

.container {
  position: relative;
  width: 100%;
  max-width: 568px;
  height: auto;
  margin: auto;
  background-color: white;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $screen-tablet-min) {
    max-height: 90vh;
    border-radius: $border-radius;
    overflow: hidden;
  }
}

.container-full-height {
  composes: container;
  height: 100%;
}

.container-manage-goals {
  composes: container;
  background-color: $gray-lightest;
}

.container-discover {
  composes: container;
  max-width: 475px;
  height: auto;
  text-align: center;
}

.container-nutrients {
  composes: container;
  max-width: 800px;
  max-height: -webkit-fill-available;

  button {
    @media screen and (max-width: $screen-tablet) {
      position: fixed;
    }
  }
}

.container-cross-sell {
  composes: container;
  height: auto;
  max-width: 360px;
  border-radius: 0;
  .button-close {
    top: -4px;
    right: 0;
    img {
      margin: 0;
      width: 12px;
      height: 12px;
    }
  }
}

.container-cross-sell-single {
  composes: container;
  height: auto;
  max-width: 320px;
  border-radius: 0;
  .button-close {
    top: -4px;
    right: 0;
    img {
      margin: 0;
      width: 12px;
      height: 12px;
    }
  }
}

.container-message {
  composes: container;
}

.container-confirm {
  composes: container;

  h4 {
    font-weight: $weight-medium;
  }
}
