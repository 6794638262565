@import '../../../styles/variables.scss';
@import '../../../styles/buttons.scss';

.buttonLink {
  @extend .buttonLink;
}

.title {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  > h5 {
    margin-bottom: $spacing-base * 2;
  }
}
