@import '../../../../styles/variables.scss';

.h2 {
  composes: h2 from '../../../../styles/typography.scss';
}

.button {
  composes: buttonPrimary from '../../../../styles/buttons.scss';

  width: 100%;
}

.input {
  composes: input from '../../../../styles/forms.scss';
}

.link-wrapper {
  text-align: center;
}

.link {
  composes: buttonLink from '../../../../styles/buttons.scss';

  margin-top: $spacing-small * 2;
  margin-bottom: $spacing-small;

  @media screen and (min-width: $screen-desktop-min) {
    margin-top: $spacing-large * 2;
    margin-bottom: $spacing-large;
  }
}

.results {
  min-width: 100%;

  &-item {
    display: block;
    padding-top: $spacing-large;
    padding-bottom: $spacing-large;
    border-top: 1px solid $gray-light;
    cursor: pointer;
    font-size: $font-small;

    @media screen and (min-width: $screen-desktop-min) {
      padding-top: $spacing-small;
      padding-bottom: $spacing-small;
    }

    &:first-child {
      border: none;
    }
  }
}

.addressDescription {
  color: $gray-light;
}

.error {
  composes: error from '../../../../styles/forms.scss';
}

.addressList {
  max-height: 194px;
  padding: 10px 0 0px 0;
  width: 100%;
  margin: -8px auto 0;
  margin-top: -9px;
  border: 1px solid $gray-lighter;
  overflow-y: scroll;
  p {
    font-size: $font-xsmall;
    padding: 8px 15px 8px 15px;
    margin: 0;
    cursor: pointer;
  }
  &:hover {
    border-color: $gray;
  }
  @media screen and (min-width: $screen-desktop-min) {
    overflow-y: auto;
    p:hover {
      background-color: $gray-lightest;
      cursor: pointer;
    }
  }
}

.addScroll {
  overflow-y: scroll;
}

.addFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addNewLine {
  display: block;
  font-size: $font-xxsmall;
  font-weight: $weight-semibold;
  color: $brand-primary-darker;
}

.addressLoading {
  font-size: $font-xxsmall;
  text-align: center;
  color: $grey-light-3;
}

.arrow {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 4px;
  margin-top: 4px;

  &:after {
    @extend %arrow;

    transform: translateY(calc(-50% - 1px)) rotate(-45deg);
  }
}
