@import '../../../../styles/variables.scss';

.footer {
  background: $white;
  padding: 0 $spacing-base * 5 $spacing-base * 2;
  @media screen and (min-width: $screen-desktop-min) and (min-height: $screen-desktop-height-min) {
    position: absolute;
    bottom: -$spacing-base * 5;
    background: transparent;
    padding: 0 0 $spacing-base * 2;
  }

  .footer__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: $gray-medium;
    max-width: $basket-width;
    margin: auto;
    .footer__poweredBy {
      padding-right: $spacing-base * 2;
      border-right: $border;

      a {
        vertical-align: middle;
      }
      img {
        width: 33px;
      }
    }
    .links {
      // padding-left: $spacing-base * 2;

      .link {
        margin-right: 12px;
      }
    }
  }
}


.change__currency{
  display: inline-flex;
  color: $gray-medium;
  cursor: pointer;
  margin-right: $spacing-large;
}
