@import '../../../../styles/variables.scss';
@import '../../../../styles/forms.scss';

.container {
  position: relative;
  padding-left: $spacing-base * 5;
  margin-bottom: $spacing-base * 2;
  text-align: left;
  cursor: pointer;
  user-select: none;
  display: flex;

  &:hover input ~ .checkMark {
    background-color: $gray-lightest;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked ~ .checkMark:after {
    display: block !important;
  }
}

.containerDisabled {
  @extend .container;
  opacity: 0.66;
}

.checkMark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 23px;
  width: 23px;
  background-color: white;
  border: 2px solid $gray-medium;

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid $gray;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.checkMarkError {
  @extend .checkMark;
  border-color: $error;
}

.error {
  @extend .error;
}

.text{
  padding-top: $spacing-base;
  font-size: $font-xsmall;
  color: $gray;
}
