@import '../../../styles/variables.scss';
@import '../../../styles/variables.scss';

.fullPageMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: $font-xxsmall;

  .container {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    text-align: center;
    color: $gray-medium;

    .title {
      font-size: $font-small;
      margin-bottom: $spacing-base;
    }
    .message {
      margin-bottom: $spacing-base * 2;
    }
  }
}
