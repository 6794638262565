@import './variables.scss';
@import './typography.scss';

html,
body,
#root {
  height: 100%;
}

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
}

body {
  margin: 0;
  font-family: $font-family;
  font-size: $font-base;
  color: $gray;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
  transition: $transition-slow;
  &:hover {
    color: $gray-dark;
  }
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $gray-lightest;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $brand-primary-darker;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $brand-primary-darker;
}
