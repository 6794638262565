@import '../../../../styles/variables.scss';
@import '../../../../styles/forms.scss';

.inputWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.placeholder {
  @extend .placeholder;
}

.placeholderActive {
  @extend .placeholderActive;
}

.container {
  position: relative;
  display: block;
  width: 100%;
  height: $spacing-base * 7;
  margin-bottom: $spacing-base;
  color: $gray;

  input {
    width: 100%;
    height: 100%;
    padding: $spacing-base * 2 $spacing-base * 2 0 $spacing-base * 2;
    border: 1px solid $gray-lighter;
    outline: 0;
    transition: 0.2s all $custom-easing;
    font-family: $font-family;
    font-size: $font-xlsmall;
    color: $gray-dark;

    &:focus,
    &:active {
      border-color: $gray;
    }
  }
}

.containerError {
  @extend .container;

  input {
    border-color: $error;

    &:focus,
    &:active {
      border-color: $error;
    }
  }
}

.error {
  @extend .error;
}

.stripeContainer {
  @extend .container;

  .input {
    border: 1px solid $gray-lighter;
    padding: 0 $spacing-base * 2;
    height: 100%;
  }

  input[name='name'] {
    height: 50px;
    font-family: 'Ideal Sans', system-ui, sans-serif;
    padding: 12px;
    background-color: #ffffff;
    border-radius: 0;
    transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease,
      color 0.15s ease;
    border: 1px solid #e6e6e6;
    box-shadow: 0 1px 1px #00000008, 0 3px 6px #00000005;
    &:focus {
      outline: 0;
      border-color: #0573e180;
      box-shadow: 0px 1px 1px #00000008, 0px 3px 6px #00000005,
        0 0 0 3px #0573e140, 0 1px 1px 0 #00000014;
    }
  }
}

.stripeErrorContainer {
  @extend .stripeContainer;
  margin-bottom: 2px;
  input[name='name'] {
    border: 1px solid $error;
    box-shadow: 0px 1px 1px #00000008, 0px 3px 6px #00000005, 0 0 0 1px $error;
  }
}

.stripeError {
  @extend .error;
  font-family: 'Ideal Sans', system-ui, sans-serif;
  font-size: 0.93rem;
  margin-bottom: 0;
}
