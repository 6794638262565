@import '../../../../styles/variables';
@import '../../../../styles/forms.scss';

.placeholder {
  @extend .placeholder;
}

.placeholderActive {
  @extend .placeholderActive;
}

.container {
  position: relative;
  display: block;
  width: 100%;
  height: $spacing-base * 7;
  margin-bottom: $spacing-small;
}

.error {
  @extend .error;
}
