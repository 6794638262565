@import '../../../styles/variables.scss';

.emailLoader {
  margin-bottom: $spacing-small;

  .icon {
    height: 30px;
    animation: box 2s linear infinite;
  }
  p {
    text-align: center;
    color: $gray-medium;
    animation: flash 2s linear infinite;
  }
}

@keyframes flash {
  0% {
    opacity: 0.4;
  }
  10% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.4;
  }
}

@keyframes box {
  0% {
    opacity: 0;
    height: 30px;
  }
  10% {
    opacity: 0.8;
    transform: translateX(30px);
  }
  14% {
    opacity: 1;
  }
  100% {
    transform: translateX(300px);
    opacity: 0;
  }
}
