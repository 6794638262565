@import '../../../styles/variables.scss';

.modal {
  box-shadow: $custom-box-shadow;
  background: $white;
  padding: $header-height $spacing-base * 5 $spacing-base * 2 $spacing-base * 5;
  transform: translateY(calc(-100%));
  transition: all 0.4s cubic-bezier(0.35, 0.25, 0.1, 1);

  max-height: 70vh; // <-- This sets the height
  overflow: auto; // <-- This tells the modal to scrol
  &:focus{
   outline: none;
  }
}

.afterOpenModal {
  transform: translateY(-1px);
  transition: all 0.4s cubic-bezier(0.35, 0.25, 0.1, 1);
}
