@import '../../../../styles/variables.scss';
@import '../../../../styles/buttons.scss';

.buttonLink {
  @extend .buttonLink;
}

.basketOrderDetails {
  max-width: $basket-width;
  margin: auto;
  overflow: auto;

  @media screen and (min-width: $screen-desktop-min) and (min-height: $screen-desktop-height-min) {
    // max-height: 55vh;
  }

  .basketOrderDetails__subtotal {
    border-bottom: $border;
  }

  .basketOrderDetails__shipping {
    border-bottom: $border;

    .item__element {
      color: $gray-medium !important;
    }
  }

  .basketOrderDetails__total {
    border-bottom: none;
  }

  .basketOrderDetails__items,
  .basketOrderDetails__subtotal,
  .basketOrderDetails__shipping,
  .basketOrderDetails__total {
    list-style: none;
    padding: 0;
    margin: 16px 0px;

    .basketOrderDetails__item {
      margin-bottom: $spacing-base * 2;

      .item__element {
        display: flex;
        justify-content: space-between;
        color: $gray-dark;
        font-weight: $weight-medium;
        gap: 10px;
      }
      .item__subElement {
        display: flex;
        justify-content: space-between;
        color: $gray-medium;
        font-size: $font-xsmall;
      }
    }
  }
}

.arrowDown {
  @extend %arrowDown;
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 4px;
  margin-top: 4px;
}

.arrowUp {
  @extend %arrowUp;
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 4px;
  margin-top: 4px;
}

.warning {
  color: $error;
  font-size: $font-xsmall;
  font-weight: $weight-semibold;
}

.bold {
  font-weight: $weight-bold;
}
