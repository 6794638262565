@import './variables.scss';

h1,
.h1 {
  font-family: $font-family-header;
  margin-top: 0;
}

h2,
.h2 {
  font-family: $font-family-header;
}

h3,
.h3,
h4,
.h4,
h5,
.h5 {
  margin: 0;
  font-family: $font-family;
  margin-top: 0;
}

@mixin make-heading-with-hr($font-size, $line-height) {
  font-size: $font-size;
  line-height: $line-height;
  margin-bottom: $line-height;

  + hr {
    margin-top: -(calc($line-height / 2));
    margin-bottom: $line-height * 0.75;
  }
}

h1,
.h1 {
  @include make-heading-with-hr($font-medium, $spacing-base * 4);

  @media screen and (min-width: $screen-mobile) {
    @include make-heading-with-hr($font-xlarge, $spacing-base * 4);
  }

  @media screen and (min-width: $screen-desktop) {
    @include make-heading-with-hr(40px, $spacing-base * 6);
  }
}

h2,
.h2 {
  @include make-heading-with-hr(22px, $spacing-base * 4);
  font-weight: $weight-bold;

  @media screen and (min-width: $screen-mobile) {
    @include make-heading-with-hr($font-medium, $spacing-base * 4);
  }

  @media screen and (min-width: $screen-desktop) {
    @include make-heading-with-hr($font-xxlarge, $spacing-base * 5);
  }
}

h3,
.h3 {
  @include make-heading-with-hr($font-small, $spacing-base * 4);
  font-weight: $weight-medium;

  @media screen and (min-width: $screen-mobile) {
    @include make-heading-with-hr(22px, $spacing-base * 4);
  }

  @media screen and (min-width: $screen-desktop) {
    @include make-heading-with-hr($font-xlarge, $spacing-base * 5);
  }
}

h4,
.h4 {
  @include make-heading-with-hr($font-small, $spacing-base * 4);
  font-weight: $weight-medium;

  @media screen and (min-width: $screen-mobile) {
    @include make-heading-with-hr(22px, $spacing-base * 4);
  }

  @media screen and (min-width: $screen-desktop) {
    @include make-heading-with-hr($font-medium, $spacing-base * 4);
  }
}

h5,
.h5 {
  @include make-heading-with-hr(18px, $spacing-base * 3);
  font-weight: $weight-bold;

  @media screen and (min-width: $screen-mobile) {
    @include make-heading-with-hr($font-small, $spacing-base * 4);
  }

  @media screen and (min-width: $screen-desktop) {
    @include make-heading-with-hr($font-small, $spacing-base * 3);
  }
}

h6,
.h6 {
  @include make-heading-with-hr(18px, $spacing-base * 3);
  font-weight: $weight-medium;

  @media screen and (min-width: $screen-mobile) {
    @include make-heading-with-hr($font-small, $spacing-base * 4);
  }

  @media screen and (min-width: $screen-desktop) {
    @include make-heading-with-hr($font-small, $spacing-base * 3);
  }
}

.subheading {
  @include make-heading-with-hr($font-small, $spacing-base * 3);

  @media screen and (min-width: $screen-mobile) {
    @include make-heading-with-hr($font-small, $spacing-base * 3);
  }

  @media screen and (min-width: $screen-desktop) {
    @include make-heading-with-hr($font-large, $spacing-base * 4);
  }
}

.subheading-2 {
  @include make-heading-with-hr(18px, $spacing-base * 3);

  @media screen and (min-width: $screen-mobile) {
    @include make-heading-with-hr(18px, $spacing-base * 3);
  }

  @media screen and (min-width: $screen-desktop) {
    @include make-heading-with-hr($font-small, $spacing-base * 3);
  }
}

p {
  margin-bottom: $spacing-base * 2;

  + hr {
    margin-bottom: $spacing-base * 2;
  }

  a {
    font-weight: $weight-semibold;
    color: $gray;
    text-decoration: none;
    border-bottom: 1px solid currentColor;
    transition: all 0.2s $custom-easing;

    &:hover {
      color: $gray-dark;
    }
  }
}

sup {
  a {
    border-bottom: none;
  }
}

strong,
.bold {
  font-weight: $weight-bold;
}

.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;
}

.alignRight {
  text-align: right;
}

hr,
.hr {
  display: block;
  height: 2px;
  max-width: 75px;
  margin-left: auto;
  margin-right: auto;
  border: 0;
  background: $brand-primary;
}

.hr-dark {
  @extend .hr;
  background: $gray-dark;
}

.hrLight {
  @extend .hr;
  background: white;
}

.hrLeftAligned {
  @extend .hr;

  @media screen and (min-width: $screen-desktop-min) {
    margin-left: 0;
    margin-right: 0;
  }
}

.hrFullWidth {
  @extend .hr-dark;
  max-width: none;
  margin: 0;
  width: 100%;
}
