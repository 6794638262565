@import '../../../../styles/variables.scss';
@import '../../../../styles/buttons.scss';

.arrowDown {
  @extend %arrowDown;
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 4px;
  margin-top: 4px;
}

.buttonDetails {
  @extend .buttonLink;

  @media screen and (min-width: $screen-desktop-min) and (min-height: $screen-desktop-height-min) {
    display: none;
  }
}
