@import './variables.scss';
// transition group style classes for mount/unmount component (overlay)
.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: opacity 0.3s $custom-easing;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
  transition: opacity 0.3s $custom-easing;
}
