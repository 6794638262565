@import '../../../styles/variables.scss';

.error {
  font-size: $font-xsmall;
  text-align: left;
  margin-bottom: $spacing-base * 3;
  text-align: left;
  color: $error;
}

.errorNoMargin {
  @extend .error;
  margin-top: 0;
}
