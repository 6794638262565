@import '../../../styles/variables.scss';

.layout {
  background-image: linear-gradient(
    180deg,
    transparent,
    transparent 500px,
    #fff 0,
    #fff
  );
  min-height: 100vh;
  @media screen and (min-width: $screen-desktop-min) and (min-height: $screen-desktop-height-min) {
    background-image: unset;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &::before {
      height: 100%;
      width: 50%;
      background: #fff;
      position: fixed;
      content: ' ';
      top: 0;
      right: 0;
      transform-origin: right;
    }
  }

  .layoutBg {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
  }

  .layoutContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media screen and (min-width: $screen-desktop-min) and (min-height: $screen-desktop-height-min) {
      align-items: flex-start;
      flex-direction: row;
      width: 100%;
      max-width: 920px;
      transform: translateY(max(48px, calc(50vh - 50%)));
    }

    @media screen and (min-width: $screen-desktop-xl-min) and (min-height: $screen-desktop-height-min) {
      max-width: 984px;
    }

    .layout__left,
    .layout__right {
      padding: $spacing-base * 5;
      display: flex;
      @media screen and (min-width: $screen-desktop-min) and (min-height: $screen-desktop-height-min) {
        min-height: 550px;
        width: $basket-width;
        padding: 0;
      }
    }

    .layout__left {
      // margin-bottom: $spacing-base * 4;
    }

    .layout__right {
      background: white;

      @media screen and (min-width: $screen-desktop-min) and (min-height: $screen-desktop-height-min) {
        background: inherit;
      }
    }

    .right__container,
    .left__container {
      width: $basket-width;
      margin: auto;
      max-width: 100%;
      @media screen and (min-width: $screen-desktop-min) and (min-height: $screen-desktop-height-min) {
        background: inherit;
      }
    }
  }
}
