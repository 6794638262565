@import '../../../../styles/variables.scss';

.arrowDown {
  @extend %arrowDown;
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 4px;
  margin-top: 4px;
}

.arrowUp {
  @extend %arrowUp;
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 4px;
  margin-top: 4px;
}

.basketHeader {
  display: flex;
  justify-content: space-between;
  padding: $spacing-base * 2 $spacing-base * 5;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 2;
  top: 0px;
  left: 0;
  height: $header-height;
  background-color: $brand-primary;
  transition: $transition-slow;

  &.scrolled {
    box-shadow: $custom-box-shadow2;
    transition: $transition-slow;
  }

  @media screen and (min-width: $screen-desktop-min) {
    max-width: $basket-width;
    position: relative;
    padding: 0px;

    &.scrolled {
      box-shadow: unset;
    }
  }

  &.basketHeader__modal {
    background: $white !important;

    &.scrolled {
      box-shadow: unset;
    }

    @media screen and (min-width: $screen-desktop-min) {
      background: $white !important;
    }
  }

  .header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: $basket-width;
    margin: 0 auto;
  }

  a {
    position: relative;
    &:hover {
      .header__backIcon {
        transform: translateX(-22px) translateY(-50%);
        opacity: 1;
      }
      .header__back {
        opacity: 1;
        transform: translateX(0px) translateY(-50%);
      }
      .header__logo {
        opacity: 0;
        transform: translateX(-4px);
      }
    }
    .header__backIcon {
      position: absolute;
      top: 50%;
      transform: translateX(-20px) translateY(-50%);
      transition: $transition-slow;
      opacity: 0.5;
    }
    .header__back {
      transition: $transition-slow;
      color: $gray-dark;
      opacity: 0;
      font-weight: $weight-medium;
      transform: translateX(4px) translateY(-50%);
      top: 50%;
      position: absolute;
      left: 0;
    }
    .header__logo {
      transition: $transition-slow;
      width: 58px;

      @media screen and (min-width: $screen-desktop-min) {
        width: 66px;
      }
    }
  }
}
