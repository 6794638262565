@import './variables.scss';

.placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: $spacing-base * 2;
  color: $gray-lighter;
  font-size: $font-xsmall;
  transition: 0.2s all $custom-easing;
  pointer-events: none;

  display: flex;
  align-items: center;
}

.placeholderActive {
  @extend .placeholder;

  height: $spacing-base * 4;
  font-size: 10px;
}

.textarea {
  textarea {
    border: 1px solid $gray-lighter;
    outline: none;
    min-height: 240px;
    width: 100%;
    padding: $spacing-base * 2;
    margin-bottom: $spacing-base * 2;

    &:focus {
      outline: none;
    }
  }
}

.radioContainer {
  position: relative;
  display: flex;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  input:checked ~ .radioCheckmark:before {
    content: '';
    position: absolute;
    left: 4px;
    display: block;
    width: 14px;
    height: 14px;
    background-color: $brand-primary;
    border-radius: 50%;
  }
}

.radioCheckmark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid $gray;
    border-radius: 50%;
  }
}

.error {
  display: block;
  color: $error;
  margin-bottom: $spacing-base;
  text-align: left;
}
