@import '../../../../styles/variables.scss';
@import '../../../../styles/forms.scss';

.container {
  @extend .radioContainer;

  border-bottom: 1px solid $gray-border-content;
  transition: all 0.3s $custom-easing;
  border: 2px solid $white;
  border-radius: $border-radius;

  padding: $spacing-base;

  &:hover {
    background: $brand-primary-lighter;
    border: 2px solid $brand-primary-lighter;
  }
}

.containerDisabled {
  @extend .container;
  opacity: 0.66;
}

.error {
  @extend .error;
}

.checkmark {
  @extend .radioCheckmark;

  &:before {
    left: auto !important;
    right: 24px;
  }

  &:after {
    right: 21px;
  }
}
