@import '../../../styles/variables.scss';

.cardDetails {
  display: flex;
  align-items: center;
  margin-bottom: $spacing-base * 2;

  img {
    margin-right: $spacing-small;
    max-height: 33px;
  }
}
